/* width */
.product-list::-webkit-scrollbar {
  width: 0px;
}

/* Track */
.product-list::-webkit-scrollbar-track {
  background: rgb(223, 214, 204);
  border-radius: 10px;
}

/* Handle */
.product-list::-webkit-scrollbar-thumb {
  background: rgb(247, 167, 75);
  border-radius: 10px;
}

/* Handle on hover */
.product-list::-webkit-scrollbar-thumb:hover {
  background: rgb(251, 188, 100);
}

.fade-down {
  animation: fadeDown 1s infinite cubic-bezier(0.36, 0.07, 0.19, 0.97) forwards;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
}

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.fade-in {
  animation: fadeIn infinite cubic-bezier(0.36, 0.07, 0.19, 0.97) forwards;
  animation-duration: 0.8s;
  animation-iteration-count: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Radio Buttons */

/* Style the radio buttons themselves */
input[type="radio"] {
  width: 20px;
  height: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid #cbcdce;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
}

/* Style the radio buttons when they are selected */
input[type="radio"]:checked {
  box-shadow: inset 0 0 0 3px white;
  background-color: rgb(255, 140, 46);
}

/* Style the checkbox buttons */
input[type="checkbox"] {
  width: 20px;
  height: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid #cbcdce;
  border-radius: 5px;
  cursor: pointer;
}

/* Style the checkbox buttons when they are selected */
input[type="checkbox"]:checked {
  box-shadow: inset 0 0 0 3px white;
  background-color: rgb(255, 140, 46);
}

/* Scroll Styling */
/* width */
.scroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
  background-color: #e4ccfd;
  border-radius: 10px;
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: pointer;
  background-color: rgb(255, 140, 46);
}
